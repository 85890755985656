.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt-input-multi {
  cursor: text;
  position: relative;
  overflow: hidden;
}

.rbt-input-multi.focus {
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.rbt-input-multi.form-control {
  height: auto;
}

.rbt-input-multi.disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-placeholder-shown {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -1px;
  margin-bottom: -4px;
  display: flex;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 1.5rem;
}

.rbt-token {
  color: #007bff;
  background-color: #e7f4ff;
  border: 0;
  border-radius: .25rem;
  margin: 1px 3px 2px 0;
  line-height: 1rem;
  display: inline-flex;
}

.rbt-token .rbt-token-label {
  padding: .25rem .5rem;
}

.rbt-token .rbt-token-label:not(:last-child) {
  padding-right: .25rem;
}

.rbt-token-disabled {
  color: #495057;
  pointer-events: none;
  background-color: #0000001a;
}

.rbt-token-removeable {
  cursor: pointer;
}

.rbt-token-active {
  color: #fff;
  background-color: #007bff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  opacity: 1;
  text-shadow: none;
  background-image: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  justify-content: center;
  padding: .25rem .5rem .25rem 0;
  font-weight: normal;
  display: flex;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
  display: block;
}

.rbt-aux {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rbt-aux .rbt-close {
  pointer-events: auto;
  margin-top: -.25rem;
}

.has-aux .form-control {
  padding-right: 2rem;
}

.has-aux .form-control.is-valid, .has-aux .form-control.is-invalid {
  background-position: right 2rem center;
  padding-right: 4rem;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  padding: 0;
  font-weight: bold;
}

.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint, .input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbt-close {
  font-size: 1rem;
}

.rbt-close-sm {
  font-size: .75rem;
}

.rbt-close-content {
  display: none;
}

.rbt-aux {
  width: 2.5rem;
}

.rbt-aux-lg {
  width: 3rem;
}

.rbt-aux .rbt-close {
  margin-top: 0;
}

.rbt .form-floating {
  flex: 1;
}

.form-floating > .rbt-input-multi:not(:placeholder-shown) ~ label {
  opacity: inherit;
  transform: inherit;
}

.form-floating > .rbt-input-multi .rbt-input-main::placeholder {
  color: #0000;
}

.form-floating > .rbt-input-multi.focus ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}
/*# sourceMappingURL=index.76666f26.css.map */
